<template>
  <template v-if="dType === 'detail_3'">
    <!-- <pre>{{ detailData}}</pre> -->
    <template v-if="detailData.participants">
      <template v-for="betx in detailData.participants[0].bets" v-bind:key="betx">
        <div class="detailData mb-10">
          <ul>
            <li>게임타입</li>
            <li>{{ detailData.gameType }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.betAmt') }}</li>
            <li>{{ betx.stake }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.winAmt') }}</li>
            <li>{{ betx.payout }}</li>
          </ul>
          <ul>
            <li>베팅시각</li>
            <li>{{ betx.placedOn }}</li>
          </ul>
          <ul>
            <li>베팅위치</li>
            <li>{{ betx.code }}</li>
          </ul>
          <ul>
            <li>트랜젝션아이디</li>
            <li>{{ betx.transactionId }}</li>
          </ul>
        </div>
      </template>
    </template>

    <p class="dataname">베팅 및 게임결과</p>
    <div class="detailData" v-if="detailData.gameType === 'baccarat'">
      <ul>
        <li>뱅커 : {{ detailData.result.banker.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.banker.cards[0]">{{ detailData.result.banker.cards[0] }}</em>
            <em :class="'C' + detailData.result.banker.cards[1]">{{ detailData.result.banker.cards[1] }}</em>
            <em :class="'C' + detailData.result.banker.cards[2]">{{ detailData.result.banker.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <ul>
        <li>플레이어 : {{ detailData.result.player.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.player.cards[0]">{{ detailData.result.player.cards[0] }}</em>
            <em :class="'C' + detailData.result.player.cards[1]">{{ detailData.result.player.cards[1] }}</em>
            <em :class="'C' + detailData.result.player.cards[2]">{{ detailData.result.player.cards[2] }}</em>
          </div>
        </li>
      </ul>
    </div>
    <div class="detailData" v-else-if="detailData.gameType === 'blackjack'">
      <ul>
        <li>딜러: {{ detailData.result.dealer.score }}</li>
        <li class="data">
          <div class="card">
            <template v-if="detailData.result.dealer">
              <template v-for="betb in detailData.result.dealer.cards" v-bind:key="betb">
                <em :class="'C' + betb">{{ betb }}</em>
              </template>
            </template>
          </div>
        </li>
      </ul>
      <ul class="blackjack">
        <template v-for="([seatName, seatData], index) in Object.entries(detailData.result.seats)" v-bind:key="index">
          <li>{{ seatName }} : {{ seatData.score }}</li>
          <li class="data">
            <div class="card">
              <template v-for="bscard in seatData.cards" v-bind:key="bscard">
                <em :class="'C' + bscard">{{ bscard }}</em>
              </template>
            </div>
          </li>
        </template>
      </ul>
    </div>

  </template>
  <template v-else-if="dType === 'detail_4'">
    <div class="detailData">
      <ul>
        <li>게임ID</li>
        <li>{{ detailData.referer_id }}</li>
      </ul>
      <ul>
        <li>제공회사</li>
        <li>{{ detailData.vendor_key }}</li>
      </ul>
      <ul>
        <li>게임타입</li>
        <li>{{ detailData.game_type }}</li>
      </ul>
      <ul>
        <li>{{ $t('table.head.betAmt') }}</li>
        <template v-for="([mName, mData], m) in Object.entries(detailData.bets)" v-bind:key="m">
          <li>{{ mData }}</li>
        </template>
      </ul>
      <ul>
        <li>{{ $t('table.head.winAmt') }}</li>
        <li>{{ detailData.wins }}</li>
      </ul>
      <ul>
        <li>베팅시각</li>
        <li>{{ detailData.started_at }}</li>
      </ul>
      <ul>
        <li>베팅위치</li>
        <template v-for="([mName, mData], m) in Object.entries(detailData.bets)" v-bind:key="m">
          <li>{{ mName }}</li>
        </template>
      </ul>
      <ul>
        <li>트랜젝션아이디</li>
        <li>{{ detailData.transaction_id }}</li>
      </ul>
    </div>
    <p class="dataname">베팅 및 게임결과</p>
    <div class="detailData">
      <ul>
        <li>뱅커 : {{ detailData.result.bankerHand.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData['result'].bankerHand.cards[0]">{{ detailData["result"].bankerHand.cards[0] }}</em>
            <em :class="'C' + detailData['result'].bankerHand.cards[1]">{{ detailData["result"].bankerHand.cards[1] }}</em>
            <em :class="'C' + detailData['result'].bankerHand.cards[2]">{{ detailData["result"].bankerHand.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <ul>
        <li>플레이어 : {{ detailData.result.playerHand.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData['result'].playerHand.cards[0]">{{ detailData["result"].playerHand.cards[0] }}</em>
            <em :class="'C' + detailData['result'].playerHand.cards[1]">{{ detailData["result"].playerHand.cards[1] }}</em>
            <em :class="'C' + detailData['result'].playerHand.cards[2]">{{ detailData["result"].playerHand.cards[2] }}</em>
          </div>
        </li>
      </ul>
    </div>
  </template>
  <template v-else-if="dType === 'detail_5'">
    <template v-if="detailData.detail.data.participants[0]">
      <template v-for="bete in detailData.detail.data.participants[0].bets" v-bind:key="bete">
        <div class="detailData mb-10">
          <ul>
            <li>게임ID</li>
            <li>{{ detailData.detail.data.id }}</li>
          </ul>
          <ul>
            <li>제공회사</li>
            <li>{{ detailData.detail.data.gameProvider }}</li>
          </ul>
          <ul>
            <li>게임타입</li>
            <li>{{ detailData.detail.data.gameType }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.betAmt') }}</li>
            <li>{{ bete.stake }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.winAmt') }}</li>
            <li>{{ bete.payout }}</li>
          </ul>
          <ul>
            <li>베팅시각</li>
            <li>{{ bete.placedOn }}</li>
          </ul>
          <ul>
            <li>베팅위치</li>
            <li>{{ bete.code }}</li>
          </ul>
          <ul>
            <li>트랜젝션아이디</li>
            <li></li>
          </ul>
        </div>
      </template>
    </template>

    <p class="dataname">베팅 및 게임결과</p>
    <div class="detailData">
      <ul>
        <li>뱅커 : {{ detailData.detail.data.result.banker.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.detail.data.result.banker.cards[0]">{{ detailData.detail.data.result.banker.cards[0] }}</em>
            <em :class="'C' + detailData.detail.data.result.banker.cards[1]">{{ detailData.detail.data.result.banker.cards[1] }}</em>
            <em :class="'C' + detailData.detail.data.result.banker.cards[2]">{{ detailData.detail.data.result.banker.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <ul>
        <li>플레이어 : {{ detailData.detail.data.result.player.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.detail.data.result.player.cards[0]">{{ detailData.detail.data.result.player.cards[0] }}</em>
            <em :class="'C' + detailData.detail.data.result.player.cards[1]">{{ detailData.detail.data.result.player.cards[1] }}</em>
            <em :class="'C' + detailData.detail.data.result.player.cards[2]">{{ detailData.detail.data.result.player.cards[2] }}</em>
          </div>
        </li>
      </ul>
    </div>
  </template>
  <template v-else-if="dType === 'detail_6'">
    <!-- <pre>{{ detailData}}</pre> -->
    <template v-if="detailData.details">
      <template v-for="betx in detailData.details.bets" v-bind:key="betx">
        <div class="detailData mb-10">
          <ul>
            <li>게임ID</li>
            <li>{{ detailData.details.playerId }}</li>
          </ul>
          <ul>
            <li>제공회사</li>
            <li>{{ detailData.vendor }}</li>
          </ul>
          <ul>
            <li>게임타입</li>
            <li>{{ detailData.gameType }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.betAmt') }}</li>
            <li>{{ betx.stake }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.winAmt') }}</li>
            <li>{{ betx.payout }}</li>
          </ul>
          <ul>
            <li>베팅시각</li>
            <li>{{ betx.placedOn }}</li>
          </ul>
          <ul>
            <li>베팅위치</li>
            <li>{{ betx.code }}</li>
          </ul>
          <ul>
            <li>트랜젝션아이디</li>
            <li>{{ betx.transactionId }}</li>
          </ul>
        </div>
      </template>
    </template>

    <p class="dataname">베팅 및 게임결과</p>
    <div class="detailData" v-if="detailData.gameType === 'baccarat'">
      <ul>
        <li>뱅커 : {{ detailData.result.banker.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.banker.cards[0]">{{ detailData.result.banker.cards[0] }}</em>
            <em :class="'C' + detailData.result.banker.cards[1]">{{ detailData.result.banker.cards[1] }}</em>
            <em :class="'C' + detailData.result.banker.cards[2]">{{ detailData.result.banker.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <ul>
        <li>플레이어 : {{ detailData.result.player.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.player.cards[0]">{{ detailData.result.player.cards[0] }}</em>
            <em :class="'C' + detailData.result.player.cards[1]">{{ detailData.result.player.cards[1] }}</em>
            <em :class="'C' + detailData.result.player.cards[2]">{{ detailData.result.player.cards[2] }}</em>
          </div>
        </li>
      </ul>
    </div>
    <div class="detailData" v-else-if="detailData.gameType === 'eth'">
      <ul>
        <li>보너스</li>
        <li class="data">
          <div class="card">
            <template v-if="detailData.result.bonus.tripsBonus">
              <template v-for="betc in detailData.result.bonus.tripsBonus.cards" v-bind:key="betc">
                <em :class="'C' + betc">{{ betc }}</em>
              </template>
            </template>
          </div>
        </li>
      </ul>
      <ul>
        <li>딜러</li>
        <li class="data">
          <div class="card">
            <template v-if="detailData.result.dealer">
              <template v-for="betd in detailData.result.dealer.cards" v-bind:key="betd">
                <em :class="'C' + betd">{{ betd }}</em>
              </template>
            </template>
          </div>
        </li>
      </ul>
      <ul>
        <li>플레이어</li>
        <li class="data">
          <div class="card">
            <template v-if="detailData.result.dealer">
              <template v-for="betp in detailData.result.dealer.cards" v-bind:key="betp">
                <em :class="'C' + betp">{{ betp }}</em>
              </template>
            </template>
          </div>
        </li>
      </ul>
    </div>
    <div class="detailData" v-else-if="detailData.gameType === 'lightningscalablebj' || detailData.gameType === 'scalablebetstackerbj'">
      <ul>
        <li>딜러</li>
        <li class="data">
          <div class="card">
            <template v-if="detailData.result.dealtToPlayer">
              <template v-for="betd in detailData.result.dealtToPlayer" v-bind:key="betd">
                <em :class="'C' + betd">{{ betd }}</em>
              </template>
            </template>
          </div>
        </li>
      </ul>
      <ul>
        <li>플레이어 : {{ detailData.result.dealerHand.score }}</li>
        <li class="data">
          <div class="card">
            <template v-if="detailData.result.dealerHand">
              <template v-for="betd in detailData.result.dealerHand.cards" v-bind:key="betd">
                <em :class="'C' + betd">{{ betd }}</em>
              </template>
            </template>
          </div>
        </li>
      </ul>
    </div>
    <div class="detailData" v-else-if="detailData.gameType === 'blackjack'">
      <ul>
        <li>딜러: {{ detailData.result.dealer.score }}</li>
        <li class="data">
          <div class="card">
            <template v-if="detailData.result.dealer">
              <template v-for="betb in detailData.result.dealer.cards" v-bind:key="betb">
                <em :class="'C' + betb">{{ betb }}</em>
              </template>
            </template>
          </div>
        </li>
      </ul>
      <ul class="blackjack">
        <template v-for="([seatName, seatData], index) in Object.entries(detailData.result.seats)" v-bind:key="index">
          <li>{{ seatName }} : {{ seatData.score }}</li>
          <li class="data">
            <div class="card">
              <template v-for="bscard in seatData.cards" v-bind:key="bscard">
                <em :class="'C' + bscard">{{ bscard }}</em>
              </template>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="detailData" v-if="detailData.gameType === 'dragontiger'">
      <ul>
        <li>드래곤 : {{ detailData.result.dragon.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.dragon.card">{{ detailData.result.dragon.card }}</em>
          </div>
        </li>
      </ul>
      <ul>
        <li>타이거 : {{ detailData.result.tiger.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.tiger.card">{{ detailData.result.tiger.card }}</em>
          </div>
        </li>
      </ul>
    </div>

  </template>
  <template v-else-if="dType === 'detail_7'">
    <!-- <pre>{{ detailData}}</pre> -->
    <template v-if="detailData.participants[0]">
      <template v-for="betx in detailData.participants[0].bets" v-bind:key="betx">
        <div class="detailData mb-10">
          <ul>
            <li>게임ID</li>
            <li>{{ detailData.dealer.uid }}</li>
          </ul>
          <ul>
            <li>제공회사</li>
            <li>{{ detailData.gameProvider }}</li>
          </ul>
          <ul>
            <li>게임타입</li>
            <li>{{ detailData.gameType }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.betAmt') }}</li>
            <li>{{ betx.stake }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.winAmt') }}</li>
            <li>{{ betx.payout }}</li>
          </ul>
          <ul>
            <li>베팅시각</li>
            <li>{{ betx.placedOn }}</li>
          </ul>
          <ul>
            <li>베팅위치</li>
            <li>{{ betx.code }}</li>
          </ul>
          <ul>
            <li>트랜젝션아이디</li>
            <li>{{ betx.transactionId }}</li>
          </ul>
        </div>
      </template>
    </template>

    <p class="dataname">베팅 및 게임결과</p>
    <div class="detailData" v-if="detailData.gameType === 'baccarat'">
      <ul>
        <li>뱅커 : {{ detailData.result.banker.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.banker.cards[0]">{{ detailData.result.banker.cards[0] }}</em>
            <em :class="'C' + detailData.result.banker.cards[1]">{{ detailData.result.banker.cards[1] }}</em>
            <em :class="'C' + detailData.result.banker.cards[2]">{{ detailData.result.banker.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <ul>
        <li>플레이어 : {{ detailData.result.player.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.player.cards[0]">{{ detailData.result.player.cards[0] }}</em>
            <em :class="'C' + detailData.result.player.cards[1]">{{ detailData.result.player.cards[1] }}</em>
            <em :class="'C' + detailData.result.player.cards[2]">{{ detailData.result.player.cards[2] }}</em>
          </div>
        </li>
      </ul>
    </div>
    <div class="detailData" v-else-if="detailData.gameType === 'blackjack'">
      <ul>
        <li>딜러: {{ detailData.result.dealer.score }}</li>
        <li class="data">
          <div class="card">
            <template v-if="detailData.result.dealer">
              <template v-for="betb in detailData.result.dealer.cards" v-bind:key="betb">
                <em :class="'C' + betb">{{ betb }}</em>
              </template>
            </template>
          </div>
        </li>
      </ul>
      <ul class="blackjack">
        <template v-for="([seatName, seatData], index) in Object.entries(detailData.result.seats)" v-bind:key="index">
          <li>{{ seatName }} : {{ seatData.score }}</li>
          <li class="data">
            <div class="card">
              <template v-for="bscard in seatData.cards" v-bind:key="bscard">
                <em :class="'C' + bscard">{{ bscard }}</em>
              </template>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="detailData" v-else-if="detailData.gameType === 'dragontiger'">
      <ul>
        <li>드래곤 : {{ detailData.result.dragon.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.dragon.card">{{ detailData.result.dragon.card }}</em>
          </div>
        </li>
      </ul>
      <ul>
        <li>타이거 : {{ detailData.result.tiger.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.result.tiger.card">{{ detailData.result.tiger.card }}</em>
          </div>
        </li>
      </ul>
    </div>

  </template>
  <template v-else-if="dType === 'detail_8'">
    <!-- <pre>{{ detailData}}</pre> -->
    <template v-if="detailData.data">
      <template v-for="betx in detailData.data.bets" v-bind:key="betx">
        <div class="detailData mb-10">
          <ul>
            <li>게임타입</li>
            <li>{{ detailData.data.gameType }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.betAmt') }}</li>
            <li>{{ betx.stake }}</li>
          </ul>
          <ul>
            <li>{{ $t('table.head.winAmt') }}</li>
            <li>{{ betx.payout }}</li>
          </ul>
          <ul>
            <li>베팅시각</li>
            <li>{{ betx.placedOn }}</li>
          </ul>
          <ul>
            <li>베팅위치</li>
            <li>{{ betx.code }}</li>
          </ul>
          <ul>
            <li>트랜젝션아이디</li>
            <li>{{ betx.transactionId }}</li>
          </ul>
        </div>
      </template>
    </template>

    <p class="dataname">베팅 및 게임결과</p>
    <div class="detailData" v-if="detailData.data.gameType === 'baccarat'">
      <ul>
        <li>뱅커 : {{ detailData.data.result.banker.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.data.result.banker.cards[0]">{{ detailData.data.result.banker.cards[0] }}</em>
            <em :class="'C' + detailData.data.result.banker.cards[1]">{{ detailData.data.result.banker.cards[1] }}</em>
            <em :class="'C' + detailData.data.result.banker.cards[2]">{{ detailData.data.result.banker.cards[2] }}</em>
          </div>
        </li>
      </ul>
      <ul>
        <li>플레이어 : {{ detailData.data.result.player.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.data.result.player.cards[0]">{{ detailData.data.result.player.cards[0] }}</em>
            <em :class="'C' + detailData.data.result.player.cards[1]">{{ detailData.data.result.player.cards[1] }}</em>
            <em :class="'C' + detailData.data.result.player.cards[2]">{{ detailData.data.result.player.cards[2] }}</em>
          </div>
        </li>
      </ul>
    </div>
    <div class="detailData" v-else-if="detailData.data.gameType === 'blackjack'">
      <ul>
        <li>딜러: {{ detailData.data.result.dealer.score }}</li>
        <li class="data">
          <div class="card">
            <template v-if="detailData.data.result.dealer">
              <template v-for="betb in detailData.data.result.dealer.cards" v-bind:key="betb">
                <em :class="'C' + betb">{{ betb }}</em>
              </template>
            </template>
          </div>
        </li>
      </ul>
      <ul class="blackjack">
        <template v-for="([seatName, seatData], index) in Object.entries(detailData.data.result.seats)" v-bind:key="index">
          <li>{{ seatName }} : {{ seatData.score }}</li>
          <li class="data">
            <div class="card">
              <template v-for="bscard in seatData.cards" v-bind:key="bscard">
                <em :class="'C' + bscard">{{ bscard }}</em>
              </template>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="detailData" v-else-if="detailData.data.gameType === 'Dragon Tiger'">
      <ul>
        <li>드래곤 : {{ detailData.data.result.dragon.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.data.result.dragon.card">{{ detailData.data.result.dragon.card }}</em>
          </div>
        </li>
      </ul>
      <ul>
        <li>타이거 : {{ detailData.data.result.tiger.score }}</li>
        <li class="data">
          <div class="card">
            <em :class="'C' + detailData.data.result.tiger.card">{{ detailData.data.result.tiger.card }}</em>
          </div>
        </li>
      </ul>
    </div>

  </template>
  <template v-else>
    <template v-for="item in detailData.list" :key="item.id">
      <div class="detailData">
        <ul>
          <li>게임ID</li>
          <li>{{ item["id"] }}</li>
        </ul>
        <ul>
          <li>제공회사</li>
          <li>{{ item["gameProvider"] }}</li>
        </ul>
        <ul>
          <li>게임타입</li>
          <li>{{ item["gameType"] }}</li>
        </ul>
        <ul>
          <li>테이블정보</li>
          <li>{{ item["table"].name }}</li>
        </ul>
        <template v-for="(uValue, idx) in item['participants']">
          <template v-for="(xValue, i) in uValue['bets']">
            <ul>
              <li>베팅코드({{ i + 1 }})</li>
              <li>{{ xValue.code }}</li>
            </ul>
            <ul>
              <li>{{ $t('table.head.betAmt') }}({{ i + 1 }})</li>
              <li>{{ xValue.stake }}</li>
            </ul>
            <ul>
              <li>{{ $t('table.head.winAmt') }}({{ i + 1 }})</li>
              <li>{{ xValue.payout }}</li>
            </ul>
            <ul>
              <li>베팅시각({{ i + 1 }})</li>
              <li>{{ xValue.placedOn }}</li>
            </ul>
            <ul>
              <li>베팅위치({{ i + 1 }})</li>
              <li>{{ xValue.description }}</li>
            </ul>
            <ul>
              <li>트랜젝션아이디({{ i + 1 }})</li>
              <li>{{ xValue.transactionId }}</li>
            </ul>
          </template>
        </template>
      </div>
      <p class="dataname">베팅 및 게임결과</p>
      <div class="detailData" v-if="item.result">
        <ul v-if="item.result && item.result.banker">
          <li>뱅커 : {{ item["result"].banker.score }}</li>
          <li class="data">
            <div class="card">
              <em :class="'C' + item['result'].banker.cards[0]">{{ item["result"].banker.cards[0] }}</em>
              <em :class="'C' + item['result'].banker.cards[1]">{{ item["result"].banker.cards[1] }}</em>
              <em :class="'C' + item['result'].banker.cards[2]">{{ item["result"].banker.cards[2] }}</em>
            </div>
          </li>
        </ul>
        <ul v-if="item.result && item.result.player">
          <li>플레이어 : {{ item["result"].player.score }}</li>
          <li class="data">
            <div class="card">
              <em :class="'C' + item['result'].player.cards[0]">{{ item["result"].player.cards[0] }}</em>
              <em :class="'C' + item['result'].player.cards[1]">{{ item["result"].player.cards[1] }}</em>
              <em :class="'C' + item['result'].player.cards[2]">{{ item["result"].player.cards[2] }}</em>
            </div>
          </li>
        </ul>
        <ul v-if="item.result && item.result.dealtToPlayer">
          <li>dealtToPlayer</li>
          <li class="data">
            <div class="card">
              <template v-for="(bValue, i) in item.result.dealtToPlayer">
                <em :class="'C' + item['result'].dealtToPlayer[i]">{{ item["result"].dealtToPlayer[i] }}</em>
              </template>
            </div>
          </li>
        </ul>
        <ul v-if="item.result && item.result.dealerHand">
          <li>dealerHand : {{ item["result"].dealerHand.score }}</li>
          <li class="data">
            <div class="card">
              <em :class="'C' + item['result'].dealerHand.cards[0]">{{ item["result"].dealerHand.cards[0] }}</em>
              <em :class="'C' + item['result'].dealerHand.cards[1]">{{ item["result"].dealerHand.cards[1] }}</em>
              <em :class="'C' + item['result'].dealerHand.cards[2]">{{ item["result"].dealerHand.cards[2] }}</em>
              <em :class="'C' + item['result'].dealerHand.cards[3]">{{ item["result"].dealerHand.cards[3] }}</em>
            </div>
          </li>
        </ul>
        <ul v-if="item.result && item.result.lightningPayTable">
          <li>id</li>
          <li>{{ item["result"].lightningPayTable.id }}</li>
        </ul>
        <ul v-if="item.result && item.result.lightningPayTable">
          <li>value</li>
          <li>{{ item["result"].lightningPayTable.value }}</li>
        </ul>
        <ul v-if="item.result && item.result.dragon">
          <li>dragon : {{ item["result"].dragon.score }}</li>
          <li class="data">
            <div class="card">
              <em :class="'C' + item['result'].dragon.card">{{ item["result"].dragon.card }}</em>
            </div>
          </li>
        </ul>
        <ul v-if="item.result && item.result.tiger">
          <li>tiger : {{ item["result"].tiger.score }}</li>
          <li class="data">
            <div class="card">
              <em :class="'C' + item['result'].tiger.card">{{ item["result"].tiger.card }}</em>
            </div>
          </li>
        </ul>
        <ul v-if="item.result && item.result.aSpot">
          <li>aSpot : {{ item["result"].aSpot.score }}</li>
          <li class="data">
            <div class="card">
              <em :class="'C' + item['result'].aSpot.card">{{ item["result"].aSpot.card }}</em>
            </div>
          </li>
        </ul>
        <ul v-if="item.result && item.result.bSpot">
          <li>bSpot : {{ item["result"].bSpot.score }}</li>
          <li class="data">
            <div class="card">
              <em :class="'C' + item['result'].bSpot.card">{{ item["result"].bSpot.card }}</em>
            </div>
          </li>
        </ul>
        <ul v-if="item.result && item.result.outcome">
          <li>outcome</li>
          <li>{{ item["result"].outcome }}</li>
        </ul>
        <ul v-if="item.result && item.result.buttonsCount">
          <li>buttonsCount</li>
          <li>{{ item["result"].buttonsCount }}</li>
        </ul>
        <ul v-if="item.result && item.result.luckyNumbers">
          <li>luckyNumbers</li>
          <li>{{ item["result"].luckyNumbers }}</li>
        </ul>
        <ul v-if="item.result && item.result.payoutLevel">
          <li>payoutLevel</li>
          <li>{{ item["result"].payoutLevel }}</li>
        </ul>
        <ul v-if="item.result && item.result.reachedMultiplier">
          <li>reachedMultiplier</li>
          <li>{{ item["result"].reachedMultiplier }}</li>
        </ul>
        <ul v-if="item.result && item.result.redEnvelopePayouts">
          <li>redEnvelopePayouts</li>
          <li>{{ item["result"].redEnvelopePayouts }}</li>
        </ul>
        <ul v-if="item.result && item.result.winningNumbers">
          <li>winningNumbers</li>
          <li>{{ item["result"].winningNumbers }}</li>
        </ul>
        <ul v-if="item.result && item.result.first">
          <li>first</li>
          <li>{{ item["result"].first }}</li>
        </ul>
        <ul v-if="item.result && item.result.second">
          <li>second</li>
          <li>{{ item["result"].second }}</li>
        </ul>
        <ul v-if="item.result && item.result.third">
          <li>third</li>
          <li>{{ item["result"].third }}</li>
        </ul>
        <template v-if="item.result.outcomes">
          <template v-for="(yValue, i) in item.result.outcomes">
            <template v-if="item['gameType'] === 'roulette'">
              <ul>
                <li>당첨숫자</li>
                <li>{{yValue.number}}</li>
              </ul>
              <ul>
                <li>홀짝</li>
                <li>{{yValue.type}}</li>
              </ul>
              <ul>
                <li>당첨컬러</li>
                <li>{{yValue.color}}</li>
              </ul>
            </template>
          </template>
        </template>
        <template v-if="item['gameType'] === 'craps'">
          <template v-for="(zValue, i) in item.result.rolls">
            <ul>
              <li>롤ID</li>
              <li>{{zValue.rollId}}</li>
            </ul>
            <ul>
              <li>시작시간</li>
              <li>{{zValue.startedAt}}</li>
            </ul>
            <ul>
              <li>결과1</li>
              <li>{{zValue.result.first}}</li>
            </ul>
            <ul>
              <li>결과2</li>
              <li>{{zValue.result.second}}</li>
            </ul>
          </template>
        </template>
        <template v-if="item['gameType'] === 'freebet'">
          <template v-for="(xValue, i) in item.result.wonSideBets">
            <ul>
              <li>code</li>
              <li>{{xValue.code}}</li>
            </ul>
            <ul>
              <li>combination</li>
              <li>{{xValue.combination}}</li>
            </ul>
          </template>
        </template>
        <template v-if="item['gameType'] === 'cashorcrash'">
          <template v-for="(wValue, i) in item.result.drawnBalls">
            <ul>
              <li>ballType</li>
              <li>{{wValue.ballType}}</li>
            </ul>
            <ul>
              <li>combination</li>
              <li>{{wValue.payoutLevel}}</li>
            </ul>
            <ul>
              <li>reachedMultiplier</li>
              <li>{{wValue.reachedMultiplier}}</li>
            </ul>
          </template>
        </template>
        <ul>
          <li>결과전체</li>
          <li>{{item.result}}</li>
        </ul>
      </div>
    </template>
  </template>
</template>

<script>
export default {
  name: 'vendorCode1',
  props: ['detailData', 'dType']
}
</script>

<style scoped>
.detailData .blackjack li:nth-child(odd) {width: 30%;}
.detailData .blackjack li:nth-child(even) {width: 70%;}
</style>
<script setup></script>
